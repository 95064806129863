@import "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400,500,600,700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:opsz,wght@14..32,300,400,500,700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap";
.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid #0000;
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-loading .slick-list {
  background: #fff url("ajax-loader.32c19c5c.gif") center no-repeat;
}

@font-face {
  font-family: slick;
  font-weight: normal;
  font-style: normal;
  src: url("slick.019ff462.eot");
  src: url("slick.019ff462.eot#iefix") format("embedded-opentype"), url("slick.c8d05265.woff") format("woff"), url("slick.441ccdd6.ttf") format("truetype"), url("slick.0ba9c062.svg#slick") format("svg");
}

.slick-prev, .slick-next {
  cursor: pointer;
  color: #0000;
  background: none;
  border: none;
  outline: none;
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: 0;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  color: #0000;
  background: none;
  outline: none;
}

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before, .slick-next:before {
  opacity: .75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: slick;
  font-size: 20px;
  line-height: 1;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: absolute;
  bottom: -25px;
}

.slick-dots li {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  display: inline-block;
  position: relative;
}

.slick-dots li button {
  cursor: pointer;
  color: #0000;
  background: none;
  border: 0;
  outline: none;
  width: 20px;
  height: 20px;
  padding: 5px;
  font-size: 0;
  line-height: 0;
  display: block;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  content: "•";
  text-align: center;
  opacity: .25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 20px;
  height: 20px;
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: #000;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

[type="text"], input:where(:not([type])), [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, input:where(:not([type])):focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  text-align: inherit;
  min-height: 1.5em;
}

::-webkit-datetime-edit {
  display: inline-flex;
}

::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple], [size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="checkbox"]:checked {
    appearance: auto;
  }
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="radio"]:checked {
    appearance: auto;
  }
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media (forced-colors: active) {
  [type="checkbox"]:indeterminate {
    appearance: auto;
  }
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid buttontext;
  outline: 1px auto -webkit-focus-ring-color;
}

* {
  scrollbar-color: initial;
  scrollbar-width: initial;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.\!visible {
  visibility: visible !important;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-left-\[1px\] {
  left: -1px;
}

.-right-full {
  right: -100%;
}

.-top-1 {
  top: -.25rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-12 {
  bottom: 3rem;
}

.bottom-\[90px\] {
  bottom: 90px;
}

.bottom-\[calc\(100\%-4px\)\] {
  bottom: calc(100% - 4px);
}

.left-0 {
  left: 0;
}

.left-4 {
  left: 1rem;
}

.right-0 {
  right: 0;
}

.right-1\.5 {
  right: .375rem;
}

.right-2 {
  right: .5rem;
}

.right-3 {
  right: .75rem;
}

.top-0 {
  top: 0;
}

.top-1\.5 {
  top: .375rem;
}

.top-1\/2 {
  top: 50%;
}

.top-2 {
  top: .5rem;
}

.top-full {
  top: 100%;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.float-right {
  float: right;
}

.m-0 {
  margin: 0;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.-mt-\[1px\] {
  margin-top: -1px;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-11 {
  margin-bottom: 2.75rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-2\.5 {
  margin-bottom: .625rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-auto {
  margin-left: auto;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[30vh\] {
  margin-top: 30vh;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: .25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-28 {
  height: 7rem;
}

.h-3 {
  height: .75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-80 {
  height: 20rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[100\%\] {
  height: 100%;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[13px\] {
  height: 13px;
}

.h-\[18px\] {
  height: 18px;
}

.h-\[34px\] {
  height: 34px;
}

.h-\[66px\] {
  height: 66px;
}

.h-\[70px\] {
  height: 70px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.min-h-screen {
  min-height: 100vh;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-20 {
  width: 5rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: .75rem;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-40 {
  width: 10rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-9 {
  width: 2.25rem;
}

.w-96 {
  width: 24rem;
}

.w-\[13px\] {
  width: 13px;
}

.w-\[18px\] {
  width: 18px;
}

.w-\[230px\] {
  width: 230px;
}

.w-\[40\%\] {
  width: 40%;
}

.w-\[484px\] {
  width: 484px;
}

.w-\[60\%\] {
  width: 60%;
}

.w-\[72px\] {
  width: 72px;
}

.w-\[calc\(100\%\+2px\)\] {
  width: calc(100% + 2px);
}

.w-\[calc\(100\%-144px\)\] {
  width: calc(100% - 144px);
}

.w-\[calc\(50\%\+6px\)\] {
  width: calc(50% + 6px);
}

.w-\[calc\(50\%-6px\)\] {
  width: calc(50% - 6px);
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.min-w-56 {
  min-width: 14rem;
}

.min-w-\[160px\] {
  min-width: 160px;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-96 {
  max-width: 24rem;
}

.max-w-\[1000px\] {
  max-width: 1000px;
}

.max-w-\[360px\] {
  max-width: 360px;
}

.max-w-\[484px\] {
  max-width: 484px;
}

.max-w-\[500px\] {
  max-width: 500px;
}

.max-w-\[780px\] {
  max-width: 780px;
}

.flex-1 {
  flex: 1;
}

.flex-\[0_0_4px\] {
  flex: 0 0 4px;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0\.5 {
  --tw-translate-x: .125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[22px\] {
  --tw-translate-x: 22px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-pointer {
  cursor: pointer;
}

.list-decimal {
  list-style-type: decimal;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  appearance: none;
}

.appearance-auto {
  appearance: auto;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-rows-\[0fr\] {
  grid-template-rows: 0fr;
}

.grid-rows-\[1fr\] {
  grid-template-rows: 1fr;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-2\.5 {
  column-gap: .625rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-32 {
  column-gap: 8rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-5 {
  column-gap: 1.25rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-x-\[10px\] {
  column-gap: 10px;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-ellipsis, .text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-md {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.rounded-tl-\[8px\] {
  border-top-left-radius: 8px;
}

.rounded-tr-\[8px\] {
  border-top-right-radius: 8px;
}

.border {
  border-width: 1px;
}

.border-4 {
  border-width: 4px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.border-\[\#CDCDCD\] {
  --tw-border-opacity: 1;
  border-color: rgb(205 205 205 / var(--tw-border-opacity));
}

.border-black\/10 {
  border-color: #0000001a;
}

.border-black\/40 {
  border-color: #0006;
}

.border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgb(53 102 87 / var(--tw-border-opacity));
}

.border-green-120 {
  --tw-border-opacity: 1;
  border-color: rgb(42 82 70 / var(--tw-border-opacity));
}

.border-neutral-110 {
  --tw-border-opacity: 1;
  border-color: rgb(212 210 205 / var(--tw-border-opacity));
}

.border-neutral-190 {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-l-black {
  --tw-border-opacity: 1;
  border-left-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.bg-\[\#d7e0dd\] {
  --tw-bg-opacity: 1;
  background-color: rgb(215 224 221 / var(--tw-bg-opacity));
}

.bg-\[rgba\(0\,0\,0\,0\.8\)\] {
  background-color: #000c;
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(53 102 87 / var(--tw-bg-opacity));
}

.bg-green-100\/50 {
  background-color: #35665780;
}

.bg-green-120 {
  --tw-bg-opacity: 1;
  background-color: rgb(42 82 70 / var(--tw-bg-opacity));
}

.bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 233 228 / var(--tw-bg-opacity));
}

.bg-neutral-110 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 210 205 / var(--tw-bg-opacity));
}

.bg-neutral-120 {
  --tw-bg-opacity: 1;
  background-color: rgb(189 186 182 / var(--tw-bg-opacity));
}

.bg-neutral-190\/85 {
  background-color: #000000d9;
}

.bg-neutral-20 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 251 250 / var(--tw-bg-opacity));
}

.bg-neutral-40 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 246 244 / var(--tw-bg-opacity));
}

.bg-neutral-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 247 255 / var(--tw-bg-opacity));
}

.bg-neutral-60 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 242 239 / var(--tw-bg-opacity));
}

.bg-neutral-60\/70 {
  background-color: #f4f2efb3;
}

.bg-neutral-80 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 237 233 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/80 {
  background-color: #fffc;
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}

.bg-ico-arrow-down {
  background-image: url("arrow-down.f86e6377.svg");
}

.bg-ico-arrow-right {
  background-image: url("arrow-right.7b154e08.svg");
}

.bg-ico-bookmark {
  background-image: url("bookmark.07710b0f.svg");
}

.bg-ico-check {
  background-image: url("check.6f528ead.svg");
}

.bg-ico-check-circle {
  background-image: url("check-circle.257b161d.svg");
}

.bg-ico-close {
  background-image: url("close.935cb688.svg");
}

.bg-ico-close-white {
  background-image: url("close-white.2de328bd.svg");
}

.bg-ico-contact {
  background-image: url("contact.7da0859a.svg");
}

.bg-ico-delete {
  background-image: url("delete.78f42f30.svg");
}

.bg-ico-download {
  background-image: url("download.5b9f7612.svg");
}

.bg-ico-download-white {
  background-image: url("download-white.2dae1bd5.svg");
}

.bg-ico-edit {
  background-image: url("edit.aa297ca9.svg");
}

.bg-ico-edit-white {
  background-image: url("edit-white.ac94e56d.svg");
}

.bg-ico-filters {
  background-image: url("filters.f6f27973.svg");
}

.bg-ico-google {
  background-image: url("google.3ed1776f.svg");
}

.bg-ico-home {
  background-image: url("home.9406bd4b.svg");
}

.bg-ico-how-to-use {
  background-image: url("how-to-use.1e1b3364.svg");
}

.bg-ico-influencer {
  background-image: url("influencer.d08e43d7.svg");
}

.bg-ico-info {
  background-image: url("info.e4c1d4aa.svg");
}

.bg-ico-link {
  background-image: url("link.d8928097.svg");
}

.bg-ico-link-out {
  background-image: url("link-out.fa0c5cc6.svg");
}

.bg-ico-mark-read {
  background-image: url("mark-read.2fe0b09d.svg");
}

.bg-ico-newspaper {
  background-image: url("newspaper.cac6a998.svg");
}

.bg-ico-notifications {
  background-image: url("notifications.71bcce3d.svg");
}

.bg-ico-search {
  background-image: url("search.f309dba6.svg");
}

.bg-ico-sort {
  background-image: url("sort.3b3e1e9b.svg");
}

.bg-ico-submit-video {
  background-image: url("submit-video.99f3fea5.svg");
}

.bg-ico-view-video {
  background-image: url("view-video.8fc28821.svg");
}

.bg-ico-zeitgeist {
  background-image: url("zeitgeist.30035c50.svg");
}

.bg-source-ap {
  background-image: url("ap.adfaded7.svg");
}

.bg-source-bi {
  background-image: url("business_insider.d1998c05.svg");
}

.bg-source-breitbart {
  background-image: url("breitbart.2c43eb6e.svg");
}

.bg-source-cbs {
  background-image: url("cbs.65689142.svg");
}

.bg-source-cnn {
  background-image: url("cnn.4094b1eb.svg");
}

.bg-source-dc {
  background-image: url("daily_caller.07d9c745.svg");
}

.bg-source-dk {
  background-image: url("daily_kos.a9e789d6.svg");
}

.bg-source-dw {
  background-image: url("daily_wire.4f52ecbe.svg");
}

.bg-source-fox {
  background-image: url("fox_news.1df01fa8.svg");
}

.bg-source-hp {
  background-image: url("huffpost.d96f6e2e.svg");
}

.bg-source-mj {
  background-image: url("mother_jones.32980f92.svg");
}

.bg-source-msnbc {
  background-image: url("msnbc.c7fbe989.svg");
}

.bg-source-newsmax {
  background-image: url("newsmax.0a7982c5.svg");
}

.bg-source-newsweek {
  background-image: url("newsweek.ba1b3743.svg");
}

.bg-source-npr {
  background-image: url("npr.b3ef29d2.svg");
}

.bg-source-nyt {
  background-image: url("nyt.64be85ad.svg");
}

.bg-source-reuters {
  background-image: url("reuters.353d7774.svg");
}

.bg-source-time {
  background-image: url("time.7f7d35c0.svg");
}

.bg-source-wapo {
  background-image: url("wapo.2603d44c.svg");
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-left {
  background-position: 0;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-0 {
  padding: 0;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-\[56\.25\%\] {
  padding-bottom: 56.25%;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-\[104px\] {
  padding-left: 104px;
}

.pr-11 {
  padding-right: 2.75rem;
}

.pr-20 {
  padding-right: 5rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-28 {
  padding-top: 7rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pt-\[89px\] {
  padding-top: 89px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-inter {
  font-family: Inter, sans-serif;
}

.font-noto {
  font-family: Noto Sans, sans-serif;
}

.font-source_serif_pro {
  font-family: source_serif_proregular, sans-serif;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[11px\] {
  font-size: 11px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-\[73px\] {
  font-size: 73px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.leading-8 {
  line-height: 2rem;
}

.leading-\[1\.4rem\] {
  line-height: 1.4rem;
}

.leading-\[14px\] {
  line-height: 14px;
}

.leading-\[24px\] {
  line-height: 24px;
}

.leading-\[28px\] {
  line-height: 28px;
}

.leading-\[31px\] {
  line-height: 31px;
}

.leading-\[48px\] {
  line-height: 48px;
}

.leading-\[56px\] {
  line-height: 56px;
}

.leading-\[88px\] {
  line-height: 88px;
}

.leading-none {
  line-height: 1;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.text-\[\#151515\] {
  --tw-text-opacity: 1;
  color: rgb(21 21 21 / var(--tw-text-opacity));
}

.text-\[\#2f2f2e\] {
  --tw-text-opacity: 1;
  color: rgb(47 47 46 / var(--tw-text-opacity));
}

.text-green-100 {
  --tw-text-opacity: 1;
  color: rgb(53 102 87 / var(--tw-text-opacity));
}

.text-green-120 {
  --tw-text-opacity: 1;
  color: rgb(42 82 70 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-neutral-120 {
  --tw-text-opacity: 1;
  color: rgb(189 186 182 / var(--tw-text-opacity));
}

.text-neutral-140 {
  --tw-text-opacity: 1;
  color: rgb(142 140 137 / var(--tw-text-opacity));
}

.text-neutral-150 {
  --tw-text-opacity: 1;
  color: rgb(118 117 114 / var(--tw-text-opacity));
}

.text-neutral-160 {
  --tw-text-opacity: 1;
  color: rgb(94 93 91 / var(--tw-text-opacity));
}

.text-neutral-170 {
  --tw-text-opacity: 1;
  color: rgb(71 70 68 / var(--tw-text-opacity));
}

.text-neutral-180 {
  --tw-text-opacity: 1;
  color: rgb(47 47 46 / var(--tw-text-opacity));
}

.text-neutral-190 {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.no-underline {
  text-decoration-line: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opacity-25 {
  opacity: .25;
}

.opacity-30 {
  opacity: .3;
}

.opacity-75 {
  opacity: .75;
}

.shadow-filters {
  --tw-shadow: 0px -150px 60px #00000003, 0px -84px 51px #0000000d, 0px -37px 37px #00000017, 0px -9px 21px #0000001a;
  --tw-shadow-colored: 0px -150px 60px var(--tw-shadow-color), 0px -84px 51px var(--tw-shadow-color), 0px -37px 37px var(--tw-shadow-color), 0px -9px 21px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline-0 {
  outline-width: 0;
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[grid-template-rows\] {
  transition-property: grid-template-rows;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track);
  border-radius: var(--scrollbar-track-radius);
}

.scrollbar::-webkit-scrollbar-track:hover {
  background-color: var(--scrollbar-track-hover, var(--scrollbar-track));
}

.scrollbar::-webkit-scrollbar-track:active {
  background-color: var(--scrollbar-track-active, var(--scrollbar-track-hover, var(--scrollbar-track)));
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: var(--scrollbar-thumb-radius);
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover, var(--scrollbar-thumb));
}

.scrollbar::-webkit-scrollbar-thumb:active {
  background-color: var(--scrollbar-thumb-active, var(--scrollbar-thumb-hover, var(--scrollbar-thumb)));
}

.scrollbar::-webkit-scrollbar-corner {
  background-color: var(--scrollbar-corner);
  border-radius: var(--scrollbar-corner-radius);
}

.scrollbar::-webkit-scrollbar-corner:hover {
  background-color: var(--scrollbar-corner-hover, var(--scrollbar-corner));
}

.scrollbar::-webkit-scrollbar-corner:active {
  background-color: var(--scrollbar-corner-active, var(--scrollbar-corner-hover, var(--scrollbar-corner)));
}

.scrollbar {
  scrollbar-width: auto;
  scrollbar-color: var(--scrollbar-thumb, initial) var(--scrollbar-track, initial);
}

.scrollbar::-webkit-scrollbar {
  width: var(--scrollbar-width, 16px);
  height: var(--scrollbar-height, 16px);
  display: block;
}

.scrollbar-track-neutral-100 {
  --scrollbar-track: #ece9e4 !important;
}

.scrollbar-thumb-neutral-160\/20 {
  --scrollbar-thumb: #5e5d5b33 !important;
}

@font-face {
  font-family: source_serif_proregular;
  font-style: normal;
  font-weight: normal;
  src: url("sourceserifpro-regular-webfont.2e18cb92.woff2") format("woff2"), url("sourceserifpro-regular-webfont.b8413ae3.woff") format("woff");
}

.placeholder\:text-neutral-190::placeholder {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.group:hover .group-hover\:visible {
  visibility: visible;
}

.group\/account:hover .group-hover\/account\:block, .group\/clip:hover .group-hover\/clip\:block, .group\/transcript:hover .group-hover\/transcript\:block, .group:hover .group-hover\:block {
  display: block;
}

.group:hover .group-hover\:underline {
  text-decoration-line: underline;
}

.hover\:appearance-none:hover {
  appearance: none;
}

.hover\:border-green-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(53 102 87 / var(--tw-border-opacity));
}

.hover\:bg-green-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(53 102 87 / var(--tw-bg-opacity));
}

.hover\:bg-green-100\/80:hover {
  background-color: #356657cc;
}

.hover\:bg-green-120:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(42 82 70 / var(--tw-bg-opacity));
}

.hover\:bg-green-20:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(215 224 221 / var(--tw-bg-opacity));
}

.hover\:bg-neutral-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 233 228 / var(--tw-bg-opacity));
}

.hover\:bg-neutral-100\/80:hover {
  background-color: #ece9e4cc;
}

.hover\:bg-neutral-110:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(212 210 205 / var(--tw-bg-opacity));
}

.hover\:bg-neutral-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 247 255 / var(--tw-bg-opacity));
}

.hover\:bg-neutral-80:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 237 233 / var(--tw-bg-opacity));
}

.hover\:bg-white\/20:hover {
  background-color: #fff3;
}

.hover\:text-\[\#2a5246\]:hover {
  --tw-text-opacity: 1;
  color: rgb(42 82 70 / var(--tw-text-opacity));
}

.hover\:text-green-100:hover {
  --tw-text-opacity: 1;
  color: rgb(53 102 87 / var(--tw-text-opacity));
}

.hover\:text-neutral-170:hover {
  --tw-text-opacity: 1;
  color: rgb(71 70 68 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.focus\:border-green-100:focus {
  --tw-border-opacity: 1;
  border-color: rgb(53 102 87 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:outline-0:focus {
  outline-width: 0;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px;
}

.disabled\:bg-gray-400:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

@media (width >= 640px) {
  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 1024px) {
  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 1280px) {
  .xl\:relative {
    position: relative;
  }

  .xl\:right-auto {
    right: auto;
  }

  .xl\:top-auto {
    top: auto;
  }

  .xl\:block {
    display: block;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:w-96 {
    width: 24rem;
  }

  .xl\:w-\[calc\(100\%-384px\)\] {
    width: calc(100% - 384px);
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:border {
    border-width: 1px;
  }

  .xl\:border-neutral-110 {
    --tw-border-opacity: 1;
    border-color: rgb(212 210 205 / var(--tw-border-opacity));
  }

  .xl\:bg-neutral-60 {
    --tw-bg-opacity: 1;
    background-color: rgb(244 242 239 / var(--tw-bg-opacity));
  }

  .xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .xl\:px-\[40px\] {
    padding-left: 40px;
    padding-right: 40px;
  }

  .xl\:text-left {
    text-align: left;
  }

  .xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .xl\:leading-6 {
    line-height: 1.5rem;
  }
}

.\[\&\:\:-webkit-slider-thumb\]\:h-4::-webkit-slider-thumb {
  height: 1rem;
}

.\[\&\:\:-webkit-slider-thumb\]\:w-4::-webkit-slider-thumb {
  width: 1rem;
}

.\[\&\:\:-webkit-slider-thumb\]\:cursor-grab::-webkit-slider-thumb {
  cursor: grab;
}

.\[\&\:\:-webkit-slider-thumb\]\:appearance-none::-webkit-slider-thumb {
  appearance: none;
}

.\[\&\:\:-webkit-slider-thumb\]\:rounded-full::-webkit-slider-thumb {
  border-radius: 9999px;
}

.\[\&\:\:-webkit-slider-thumb\]\:bg-green-100::-webkit-slider-thumb {
  --tw-bg-opacity: 1;
  background-color: rgb(53 102 87 / var(--tw-bg-opacity));
}

.\[\&\:\:-webkit-slider-thumb\]\:shadow-md::-webkit-slider-thumb {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
/*# sourceMappingURL=index.6cd50ee4.css.map */
